<template>
	<div>
		<el-container>
			<!-- <el-header>
				<Header></Header>
			</el-header> -->
			<el-container>
				<Aside class="menu_list" @addTab="addTab" :isCollapse="isCollapse"></Aside>
				<el-main style="padding: 0;margin: 0;">
					<div class="bread-crumb">
						<i v-if="isCollapse" @click="handleChange" class="el-icon-s-unfold top_icon"></i>
						<i v-else @click="handleChange" class="el-icon-s-fold top_icon"></i>
						<div class="user_info">
							<!-- <span>通知</span> -->
							<span>用户名：{{userInfo.username}}</span>
							<span>{{userInfo.user_type==1?'超级管理员':userInfo.user_type==2?'业务员':userInfo.user_type==3?'报价员':''}}</span>
							<span @click="loginOff">退出</span>
						</div>
					</div>
					<!-- <div class="tabs_box">
            <el-tabs v-model="editableTabsValue" type="card" style="padding: 0;height: 40px;" closable @tab-click="upTab" @tab-remove="removeTab">
              <el-tab-pane v-for="(item, index) in editableTabs" :key="item.name" :label="item.title" :name="item.name">
              </el-tab-pane>
            </el-tabs>
          </div> -->
					<transition mode="out-in">
						<router-view></router-view>
					</transition>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
	import Aside from './components/aside'
	import Header from './components/header'
	export default {
		components: {
			Aside,
			Header
		},
		data() {
			return {
				isCollapse: false,
				text: '收起',
				editableTabsValue: 'homePage',
				editableTabs: [{
					title: '首页',
					url: 'homePage',
					content: '首页',
					name: 'homePage',
				}],
				tabIndex: 1,
				userInfo: {
					username:'',
					user_type:''
				}
			}
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem("userinfo"))
			// console.log("用户信息", JSON.parse(localStorage.getItem("userinfo")))
		},
		methods: {
			handleChange() {
				this.isCollapse = !this.isCollapse
			},
			addTab(targetName) {
				let type = false; //判断数组中有没有当前路由
				this.editableTabs.forEach(item => {
					if (item.url == targetName.url) {
						type = true
					}
				})
				// 如果tabs数组中没有当前路由 可以添加
				if (!type) {
					let newTabName = ++this.tabIndex + '';
					this.editableTabs.push({
						title: targetName.title,
						url: targetName.url,
						name: targetName.url,
						content: targetName.title
					});
					this.editableTabsValue = newTabName;
				}

				this.editableTabsValue = targetName.url
			},
			// 切换tab
			upTab(targetName) {
				this.$router.push({
					name: targetName.paneName
				})

				console.log()
			},
			removeTab(targetName) {
				if (targetName !== 'homePage') {
					let tabs = this.editableTabs;
					let activeName = this.editableTabsValue;
					if (activeName === targetName) {
						tabs.forEach((tab, index) => {
							if (tab.name === targetName) {
								let nextTab = tabs[index + 1] || tabs[index - 1];
								if (nextTab) {
									activeName = nextTab.name;
									this.$router.push({
										name: nextTab.name
									})
								}
							}
						});
					}
					this.editableTabsValue = activeName;
					this.editableTabs = tabs.filter(tab => tab.name !== targetName);
				}
			},
			// 退出登录
			loginOff() {
				window.localStorage.removeItem('token')
				window.localStorage.removeItem('userinfo')
				this.$router.push({
					path: '/login',
				})
			}
		},
	}
</script>
<style>
	.bread-crumb {
		height: 50px;
		display: flex;
		align-items: center;
		box-shadow: 0px 3px 6px 1px #F9F9F9;
		padding: 0 15px;
		box-sizing: border-box;
		background-color: #4E73DF;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #fff;
	}

	.el-header {
		background-color: #b3c0d1;
		color: #333;
		text-align: center;
		line-height: 60px;
	}

	.el-main {
		background-color: #F8FAFB;
		/* height: calc(100vh - 60px); */
		height: calc(100vh);
		padding: 0;
	}

	.menu_list {
		height: 100vh;
	}
</style>
<style>
	/* 路由的简单过渡动画效果 */
	.v-enter {
		opacity: 0;
	}

	.v-enter-to {
		opacity: 1;
	}

	.v-enter-active {
		transition: 0.3s;
	}

	.v-leave {
		opacity: 1;
	}

	.v-leave-to {
		opacity: 0;
	}

	.v-leave-active {
		transition: 0.3s;
	}

	.top_icon {
		font-size: 26px;
		cursor: pointer;
		color: #fff;
	}

	.tabs_box {
		background-color: #fff;
		width: 99%;
		margin: 16px auto;
	}

	.user_info span {
		font-size: 14px;
		margin-left: 20px;
	}
</style>