<template>
	<div style="height: 100%;">
		<el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
			<el-menu-item index="1" @click="handleClick({url:'homePage',title:'首页'})">
				<img class="menu_icon" src="../../../assets/icons/menu_icon6.png" alt="">
				<span slot="title">首页</span>
			</el-menu-item>
			<el-submenu index="2">
				<template slot="title">
					<img class="menu_icon" src="../../../assets/icons/menu_icon1.png" alt="">
					<span>业务管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item v-if="userInfo.user_type!=3" index="2-1"
						@click="handleClick({url:'addoffer',title:'新增报价'})">新增报价</el-menu-item>
					<el-menu-item index="2-2" @click="handleClick({url:'offermanage',title:'报价管理'})">报价管理</el-menu-item>
					<el-menu-item v-if="userInfo.user_type!=3" index="2-3"
						@click="handleClick({url:'ordermanage',title:'订单管理'})">订单管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="3" v-if="userInfo.user_type!=3">
				<template slot="title">
					<img class="menu_icon" src="../../../assets/icons/menu_icon2.png" alt="">
					<span>跟单管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="3-1" @click="handleClick({url:'inproduction',title:'生产中'})">生产中</el-menu-item>
					<el-menu-item index="3-2" @click="handleClick({url:'finish',title:'已完工'})">已完工</el-menu-item>
				</el-menu-item-group>
			</el-submenu>


			<el-submenu index="4" v-if="userInfo.user_type==1">
				<template slot="title">
					<img class="menu_icon" src="../../../assets/icons/menu_icon3.png" alt="">
					<span>系统设置</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="4-1" @click="handleClick({url:'setsystem',title:'系统设置'})">系统设置</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="5" v-if="userInfo.user_type==1">
				<template slot="title">
					<img class="menu_icon" src="../../../assets/icons/menu_icon4.png" alt="">
					<span>加工参数</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="5-1"
						@click="handleClick({url:'materialsmanage',title:'材料管理'})">材料管理</el-menu-item>
				</el-menu-item-group>
				<el-menu-item-group>
					<el-menu-item index="5-2"
						@click="handleClick({url:'surfacedispose',title:'表面处理'})">表面处理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="6" v-if="userInfo.user_type==1">
				<template slot="title">
					<img class="menu_icon" src="../../../assets/icons/menu_icon5.png" alt="">
					<span>账号管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="6-1" @click="handleClick({url:'usermanage',title:'用户管理'})">用户管理</el-menu-item>
				</el-menu-item-group>
				<el-menu-item-group>
					<el-menu-item index="6-2"
						@click="handleClick({url:'supermanage',title:'超级管理员'})">超级管理员</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
		</el-menu>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				userInfo: {
					user_type: ''
				}
			}
		},
		props: {
			isCollapse: {
				default: true,
			},
		},
		mounted() {
			this.userInfo = JSON.parse(localStorage.getItem("userinfo"))

		},
		methods: {
			handleClick(name) {
				this.$emit('addTab', name)
				if (name.url == 'addoffer') {
					if (this.$route.query.id) {
						setTimeout(() => {
							this.$router.go(0)
						}, 100)
					}
					this.$router.push({
						path: name.url,
						query: {
							type: 'add'
						}
					})


				} else {
					this.$router.push({
						path: name.url
					})
				}

			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
		}
	}
</script>
<style>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		height: 100%;
		overflow-y: auto;
		scrollbar-width: none;
	}

	.menu_icon {
		width: 18px;
		height: 18px;
	}
</style>